@import './variables.scss';

.header-description-component{
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  color: $lightTextColor;
  padding-top: 60px;
  position: absolute;
    top: 40%;
  left: 50%;
    transform: translateX(-50%);
  .title-description-component{
    font-family:$titleFont;
    font-size: 4rem;
    text-align: center;
    width: 100%;
  }
  .link-description-component{
    text-decoration: none;
    color: $lightTextColor;
    font-size: 1.1rem;
    border: 2px solid $lightTextColor;
    font-family: $textFont;
    border-radius: 7px;
    font-weight: bold;
    padding: 15px 13px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1440px){
  .header-description-component{
    .title-description-component{
      font-size: 2.5rem;
      top: 60%;
    }
    .link-description-component{
      top: 80%;
    }
  }
}
@media (max-width: 768px){
  .header-description-component{
    bottom: 25%;
    top: unset;
    .title-description-component{
      font-size: 1.8rem;
      top: 60%;
    }
    .link-description-component{
      top: 75%;
      font-size: 1rem;
    }
  }
}