.ActualityCarousel {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  .event-card-button {
    //width: 8%;
    width: 40px;
    height: 40px;
    align-self: center;
    margin: 2%;
    button {
      /*width:40px;*/
      /*height: 40px;*/
      background-color: white;
      color: #102E3E;
      font-weight: bold;
      border: 1px solid transparent;
      border-radius: 50%;
      box-shadow: 0 0 10px #A8A8A8;
      font-size: 2rem;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }
  }
  .actuality-card {
    padding: 1%;
    width: 15%;
    .event-img{
      width: 100%;
      height: 140px;
      object-fit: fill;
      border-radius: 10px;
    }
    .event-description-date {
      display: flex;
      justify-content: space-between;

      .event-title {
        width: 80%;
        max-height: 32px;

        .titre-event {
          font-size: 0.8rem;
          margin: 0 0 2%;
          max-height: 16px;
          width: 90%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .auteur-event {
          font-size: 0.7rem;
          margin: 0;
          max-height: 16px;
          width: 100%;
        }
      }

      .event-date {
        border: 1px solid grey;
        border-radius: 10px;
        width: 20%;
        text-align: center;
        position: relative;
        margin: -10% 2% 0 0;
        background-color: white;

        .mois-event {
          font-size: 0.7rem;
          margin: 0;
          padding: 1%;
          background-color: red;
          border-radius: 10px 10px 0 0;
          color: white;
        }

        .jour-event {
          margin: 10% 0;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }
    .description-actualite {
      min-width: 20%;
      max-width: 50%;
      border: 1px solid #E0E0E0;
      border-radius: 20px;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 2% 3% 2% 3%;
      background-color: white;
      color: #102E3E;
      z-index: 150;
      h5 {
        font-weight: bold;
        color: black;
        /*text-align: center;*/
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    cursor: pointer;
  }
}
@media (min-width: 768px) and (max-width: 1440px){
  .ActualityCarousel {
    .event-card-button {
    }
    .actuality-card {
      width: 12%;
      .event-img{
        height: 100px;
      }
      .event-description-date {

        .event-title {
          width: 65%;
          .titre-event {
            font-size: 0.6rem;
          }
          .auteur-event {
            font-size: 0.5rem;
          }
        }
        .event-date {
          //width: 40px;
          width: 30%;
          //height: 40px;
          .mois-event {
            font-size: 0.5rem;
          }
          .jour-event {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
@media (max-width: 768px){
  .ActualityCarousel {
    flex-direction: column;
    align-items: center;
    .event-card-button {
      transform: rotate(90deg);
    }
    .actuality-card {
      width: 80%;
      margin-bottom: 3%;
      .event-description-date {
        .event-title {
          .titre-event {
            font-size: 1rem;
          }
          .auteur-event {
            font-size: 0.8rem;
          }
        }
        .event-date {
          width: 15%;
          .mois-event {
            font-size: 1rem;
          }
          .jour-event {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
