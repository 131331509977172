@import './variables.scss';

.article1{
  width:90%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  padding: 5% 0 15% 0;
  .article1-titles{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      font-family: $textFont;
      text-align: center;
    }
    h2{
      font-family: $titleFont;
      font-size: 2.5rem;
      font-weight: normal;
      text-align: center;
    }
  }
  .article1-content{
    display: flex;
    justify-content: space-evenly;
    margin-top: 5%;
    flex-wrap: wrap;
    .article1-content-description{
      display: flex;
      flex-direction: column;
      width: 45%;
      min-width: 300px;
      .article1-content-description-text{
        width: 100%;
        text-align: justify;
        font-size: 1.1rem;
        font-weight: 200;
        line-height: 1.5;
      }
      .article1-content-description-mention{
        color: #B1A385;
      }
    }
    .article1-content-image{
      width: 40%;
      min-width: 300px;
      display: flex;
      position: relative;
      .article1-content-image-picture{
        width: 100%;
        object-fit: contain;
      }
      .article1-content-image-star{
        width: 8%;
        height: auto;
        object-fit: scale-down;
        position: absolute;
        top:-2%;
        right: 5%;
      }
    }
  }
}

@media (max-width: 768px){
  .article1{
    padding: 50% 0 50% 0;
  }
}