@import './variables.scss';

.article2{
  width:90%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  padding: 5% 0 5% 0;
  .article2-titles{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      font-family: $textFont;
      text-align: center;
    }
    h2{
      font-family: $titleFont;
      font-size: 2.5rem;
      font-weight: normal;
      text-align: center;
    }
  }
  .article2-image{
    //width: 30%;
    height: 40vw;
    width: auto;
    min-width: 300px;
    margin:  3% auto;
    object-fit: contain;
  }
  .vieille-ville-video{
    .video-vieille-ville{
      display: flex;
      margin: auto;
      width: 70vw;
      height: 40vw;
      z-index: 10;
    }
  }
  .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
  }

  .article3-description{
    display: flex;
    width: 60%;
    text-align: center;
    flex-wrap: wrap;
    margin: 3% auto  auto;
  }
  .article2-descriptions{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 3% auto  auto;
    .article2-description{
      text-align: justify;
      width: 40%;
      min-width: 300px;
      margin-bottom: 20px;
      font-size: 1.1rem;
      font-weight: 200;
      line-height: 1.5;
    }
  }
}

@media (max-width: 768px){
  .article2{
    padding: 50% 0 50% 0;
  }
}