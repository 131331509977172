@import './variables.scss';

.restauration2{
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 10px;
  .restauration2-image1{
    width: 20vw;
    position: absolute;
    top: -7vw;
    left: -2%;
  }
  .restauration2-main-content{
    display: flex;
    width: 100%;
    position: relative;
    margin: 5% 0 5% 0;
    .restauration2-main-content-image2 {
      width: 10vw;
      height: auto;
      object-fit: scale-down;
      position: absolute;
      bottom: -5vw;
      left: -7%;
    }
    .restauration2-main-content-corpus{
      display: flex;
      flex-direction: column;
      width: 70%;
      margin: auto;
      .restauration2-main-content-corpus-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 60px auto;
        text-align: center;
        h3{
          font-family: $textFont;
        }
        h2{
          font-family: $titleFont;
          font-size: 3rem;
          font-weight: normal;
          margin:0;
          color: $red;
        }
      }
      .restauration2-main-content-text{
        text-align: justify;
        font-size: 1.1rem;
        font-weight: 200;
        line-height: 1.5;
      }
    }
    .restauration2-main-content-image3{
      width: 12vw;
      height: auto;
      object-fit: scale-down;
      position: absolute;
      bottom: -4vw;
      right: -15%;
    }
  }
}
@media (max-width: 768px) {
  .restauration2{
    padding-top: 20vw;
    padding-bottom: 20vw;
    .restauration2-image1{
      left: 5%;
      width: 55vw;
    }
    .restauration2-main-content{
      .restauration2-main-content-image2{
        left: 5%;
        width: 30vw;
        bottom: -30vw;
      }
      .restauration2-main-content-image3{
        right: -2%;
        width: 25vw;
        bottom: -30vw;
      }
      .restauration2-main-content-corpus{
        width: 80%;
      }
    }
  }
}