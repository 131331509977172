@import './variables.scss';

.navbar{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $lightTextColor;
  position: fixed;
  z-index: 400;
  .navbar-left{
    display: flex;
    margin-left: 40px;
    align-items: center;
    cursor: pointer;
    .navbar-left-arrow{
      margin-right: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navbar-left-text{
      font-size: 20px;
      color: $darkTextColor;
      font-family: $textFont;
      font-weight: bold;
      max-width: 264px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }
  .navbar-right{
    display: flex;
    margin-right: 20px;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 40px;
    border-radius: 15px;
    background-color: #EEEEEE;
    cursor: pointer;
    position: relative;
    .navbar-right-btn {
      display: flex;
      align-items: center;
      width: 300px;
      height: 40px;
      justify-content: space-between;
      .etape-selectionee {
        display: flex;
        align-items: center;
        margin-left: 10px;
        color: $darkTextColor;
        font-family: $textFont;
        font-size: 14px;
        font-weight: 500;
        max-width: 250px;

        .etape-selectionee-texte {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        svg {
          margin-right: 10px;
          min-width: 20px;
        }
      }

      .navbar-right-arrow {
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .liste-etapes {
      position: absolute;
      width: 300px;
      min-height: 100px;
      background-color: #EEEEEE;
      border-radius: 15px;
      top: 50px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .liste-etapes-etape {
        display: flex;
        align-items: center;
        height: 40px;
        cursor: pointer;
        width: 100%;

        .liste-etapes-etape-texte {
          color: $darkTextColor;
          font-family: $textFont;
          font-size: 14px;
          font-weight: 500;
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      :hover {
        background-color: #D3D3D3;
      }
    }
  }
}

@media (max-width: 768px){
  .navbar{
    .navbar-left{
      .navbar-left-text{
        font-size: 15px;
        max-width: 164px;
      }
    }
    .navbar-right{
      width: 70px;
      .navbar-right-btn{
        width: 70px;
        .etape-selectionee{
          .etape-selectionee-texte{
            display: none;
          }
        }
      }
      .liste-etapes{
        left: -230px;
      }
    }
  }
}