@import './variables.scss';

.villageDescription{
  width:90%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  .villageDescription-titles{
      display: flex;
      flex-direction: column;
      align-items: center;
    padding-top: 10vh;
    text-align: center;
     h3{
       font-family: $textFont;
       color: $darkTextColor;
     }
    h2{
      font-family: $titleFont;
      color: $red;
      font-size: 2.5rem;
      font-weight: normal;
    }
  }
  .villageDescription-presentation{
    display: flex;
    justify-content: space-evenly;
    margin-top: 5%;
    position: relative;
    flex-wrap: wrap;
    .villageDescription-etoile1{
      width: 30px;
      height: auto;
      object-fit: scale-down;
      position: absolute;
      top: 30%;
      left: 3%;
    }
    .villageDescription-image{
      width: 35%;
      min-width: 300px;
      transform: rotate(-2.9deg);
      object-fit: contain;
    }
    .villageDescription-infos{
      width: 40%;
      min-width: 300px;
      position: relative;
      .villageDescription-etoile2{
        width: 5%;
        height: auto;
        object-fit: scale-down;
        position: absolute;
        top: -25%;
        left: 45%;
      }
      .villageDescription-description{
        text-align: justify;
        width: 100%;
        margin: 7% 0;
        font-size: 1.1rem;
        font-weight: 200;
        line-height: 1.5;
      }
      .villageDescription-horaires{
        font-family: $titleFont;
        color: $red;
        border: 1px solid red;
        border-radius: 10px;
        width: 30%;
        padding: 2%;
        margin:  7% auto;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
  }
  .modale-infos-village{
    z-index: 10;
  }
}

@media (max-width: 768px){
  .villageDescription {
    .villageDescription-titles {
      padding-top: 25vh;
    }
  }
}