@import './variables.scss';

.restauration5{
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 10px;
  .restauration5-image1{
    width: 15vw;
    position: absolute;
    top: -10vw;
    left: -2%;
  }
  .restauration5-main-content{
    display: flex;
    width: 100%;
    position: relative;
    margin: 5% 0 5% 0;
    .restauration5-main-content-image2 {
      width: 13vw;
      height: auto;
      object-fit: scale-down;
      position: absolute;
      bottom: -5vw;
      left: -13%;
    }
    .restauration5-main-content-corpus{
      display: flex;
      flex-direction: column;
      width: 70%;
      margin: auto;
      .restauration5-main-content-corpus-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 60px auto;
        text-align: center;
        h3{
          font-family: $textFont;
        }
        h2{
          font-family: $titleFont;
          font-size: 3rem;
          font-weight: normal;
          margin:0;
          color: $red;
        }
      }
      .restauration5-main-content-text{
        text-align: justify;
        font-size: 1.1rem;
        font-weight: 200;
        line-height: 1.5;
      }
    }
    .restauration5-main-content-image3{
      width: 15vw;
      height: auto;
      object-fit: scale-down;
      position: absolute;
      bottom: -4vw;
      right: -14%;
    }
  }
}
@media (max-width: 768px) {
  .restauration5{
    padding-top: 20vw;
    padding-bottom: 20vw;
    .restauration5-image1{
      left: 5%;
      width: 45vw;
    }
    .restauration5-main-content{
      .restauration5-main-content-image2{
        left: 5%;
        width: 50vw;
        bottom: -25vw;
      }
      .restauration5-main-content-image3{
        right: 0;
        width: 20vw;
        bottom: -30vw;
      }
      .restauration5-main-content-corpus{
        width: 80%;
      }
    }
  }
}