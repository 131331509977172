//fonts
$titleFont: 'Jeju Hallasan', sans-serif;
$textFont: 'Inter', sans-serif;

//colors
$titleColor: #0C0C0C;
$bgButtonColor: #8A7446;
$lightTextColor: white;
$darkTextColor: #102E3E;
$background: #6E0200;
$footer-bg-color: #4092BA;
$bg-color-article2 : #D0CABB;
$bg-color-main: #EFEFEF;
$red: #C62528;