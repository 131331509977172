@import './variables.scss';

.parcours{
  .parcours-header{
    margin:0;
    width: 100%;
    padding-bottom: 341px;
    background:
            linear-gradient(
                            to bottom,
                            rgba(110, 2, 0, 0.6) 0%,

                            rgba(110, 2, 0, 1) 100%
            ),
            url('../Assets/Nicolas.png') center no-repeat;
    background-size: cover;
    padding-top: 60px;
    .parcours-header-title{
      margin: 0;
      color:$lightTextColor;
      font-family: $titleFont;
      font-weight:normal;
      text-align: center;
      font-size: 3rem;
      display: flex;
      justify-content: center;
      padding: 0;
      padding-top: 221px;
    }
  }
  .histoire-st-Nicolas{
    height: 35vh;
    width: 55%;
    display: flex;
    flex-direction: row;
    background-color: white;
    position: absolute;
    left:50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow:0 2px 4px rgba(0, 0, 0, 0.25) ;
  }
  .plans-des-villages{
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $bg-color-main;
    .plans-des-villages-title{
      font-family: $titleFont;
      font-weight:normal;
      text-align: center;
      font-size: 3rem;
      display: flex;
      justify-content: center;
      padding: 10% 0 5% 0;
      margin-bottom:0;
    }
    .plan-des-villages-description-images{
      width: 70%;
      margin: auto;
      display: flex;
      position: relative;
      .plan-des-villages-description{
        width: 75%;
        font-family: $textFont;
        font-size: 1.2rem;
      }
    }
    .plan-des-villages-carte{
      width: 70%;
      height: 70vh;
      min-height: 70vh;
      background-color:#D9D9D9;
      border-radius:10px;
      margin:1% auto 5%;
      position: relative;
      z-index: 1;

      .plan-des-villages-images{
        width: 13vw;
        position: absolute;
        transform: translateY(calc(-70% - 100px));
        right: -5%;
        top: -2vw;
        .plan-des-villages-image1{
          width: 60%;
        }
        .plan-des-villages-image2{
          width: 40%;
          position: absolute;
          top: 40%;
          right: 40%;
        }
      }
    }
  }
  .principaux-evenement{
    height: 80vh;
    width: 100%;
    .principaux-evenements-title{
      display: flex;
      width: 80%;
      margin: auto;
      img{
        width: 10%;
      }
      h2{
        width: 80%;
        text-align: center;
        align-self: center;
        font-family: $titleFont;
        font-weight:normal;
        font-size: 3rem;
      }
    }
    .principaux-evenement-carousel{
      width: 100%;
      display: flex;
      //justify-content: center;
      margin:auto;
    }
  }
}
@media (min-width: 768px) and (max-width: 1440px){
  .parcours{
    .parcours-header{
      .parcours-header-title{
        font-size: 2.5rem;
      }
    }
    .histoire-st-Nicolas{
      width: 70%;
    }
    .plans-des-villages {
      .plans-des-villages-title {
        font-size: 2.5rem;
        padding: 200px 0 5% 0;
      }

      .plan-des-villages-description-images {
        width: 80%;

        .plan-des-villages-description {
          font-size: 1rem;
        }

        .plan-des-villages-images {
          top: 115%;
          right: 5%;
        }
      }
    }
    .principaux-evenement{
      height: 60vh;
      .principaux-evenements-title{
        h2{
            font-size: 2rem;
        }
      }
    }
  }
}
@media (max-width: 768px){
  .parcours{
    .parcours-header{
      height:60vh;
      .parcours-header-title{
        font-size: 1.8rem;
      }
    }
    .histoire-st-Nicolas{
      width: 90%;
      height: 400px;
      transform: translate(-50%, -30%);
      .img-Saint-Nicolas{
        display:none;
      }
    }
    .plans-des-villages {
      .plans-des-villages-title {
        font-size: 2rem;
        padding: 300px 0 5% 0;
      }

      .plan-des-villages-description-images {
        width: 100%;
        margin: auto;

        .plan-des-villages-description {
          font-size: 1rem;
          width: 90%;
          margin: auto;
        }

        .plan-des-villages-images {
          display: none;
        }
      }
    }
    .principaux-evenement{
      height: 40vh;
      width: 100%;
      .principaux-evenements-title{
        img{
          display:none;
        }
        h2{
          font-size: 2rem;
          width: 100%;
        }
      }
    }
  }
}