@import './variables.scss';

.village3 {
  font-family: $textFont;
  background-color: $bg-color-main;
  .village3-header {
    margin: 0;
    width: 100%;
    padding-bottom: 341px;
    background: linear-gradient(
                    to bottom,
                    rgba(68, 83, 143, 0.7) 0%,
                    rgba(28, 35, 53, 0.9) 100%
    ),
    url('../Assets/villages/roue.png');
    background-size: cover;
    .titre-village{
      display: flex;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 210px;
      img{
        width: 80px;
        object-fit: scale-down;
        margin-right: 50px;
      }
      .etape-header-title {
        color: $lightTextColor;
        margin: 0;
        font-family: $titleFont;
        font-weight: normal;
        text-align: center;
        font-size: 3rem;
        display: flex;
        justify-content: center;
        padding: 0;
      }
    }

  }

  .histoire-st-Nicolas {
    min-height: 320px;
    height: auto;
    width: 65%;
    display: flex;
    flex-direction: row;
    background-color: white;
    position: relative;
    margin-top: -10%;
    //transform: translate(-50%, -50%);
    transform: translate(-50%);
    left: 50%;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    padding-bottom: 1%;
    .img-Saint-Nicolas {
      position: relative;
      top: 15%;
      left: -4%;
      height: 20vh;
      margin: 0;
      width: 5vw;
    }
  }

  .presentation-village{
    width: 100%;
    //padding: 10% 0 10% 0;
    padding-bottom: 10%;
    background-color:$bg-color-main;
  }

  .ventre-nancy{
    background-color:$bgButtonColor;
    color: white;
  }

  .carriere{
    background-color: $bg-color-article2;
    //margin-bottom: 5%;
    padding-bottom: 10%;
  }

  .village1-restauration{
    width: 60%;
    margin: -5% auto 5% auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .stars{
    width: 100%;
    position: relative;
    margin: -5% 0 10% 0;
    img{
      width: 4%;
      position: absolute;
      right: 8%;
    }
  }

  .arabesque{
    width: 100%;
    display: flex;
    justify-content: center ;
    margin-bottom: 5%;
    img{
      width: 40%;
    }
  }

  .village1-carousel{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 5%;
    h3{
      font-family: $textFont;
    }
    h2{
      font-family: $titleFont;
      font-size: 2.5rem;
      font-weight: normal;
      margin:0 0 2% 0;
      color: $red;
    }
  }

  .modale-infos-village {
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 768px) {
  .village3 {
    .village1-restauration{
      width: 95%;
    }
    .histoire-st-Nicolas{
      width: 95%;
      //height: 400px;
    }
    .modale-infos-village {
      width: 300px;
    }
    .village1-carousel {
      text-align: center;
    }
    .village3-header{
      .titre-village{
        img{
          margin-right: 0;
        }
      }
    }

  }
}