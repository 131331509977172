@import './variables.scss';

.audio-reader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $textFont;
  font-weight: 600;
  font-size: 0.75rem;
  margin-top: 160px;

  .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 560px;
    .play-pause{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100px;
      min-height: 100px;
      max-height: 100px;
      min-width: 100px;
      max-width: 100px;
      border-radius: 50%;
      border: 3px solid white;
      cursor: pointer;
      margin-bottom: 26px;
    }
    img {
      width: 100px;
      margin-bottom: 26px;
    }

    input {
      width: 100%;
      -webkit-appearance: none;
      height: 2px;
      cursor: pointer;
      margin: 0;
      margin-bottom: 12px;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }

      &::-moz-range-thumb {
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }
    }

    .time-track {
      width: 100%;
      display: flex;
      justify-content: space-between;

      span {
        color: white;
      }
    }
  }
}

@media (max-width: 768px) {
  .audio-reader{
    .player{
      width: calc(100vw - 50px);
    }
  }
}