@import './variables.scss';

.restauration{
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 10px;
  .restauration-image1{
    width: 18vw;
    position: absolute;
    top: -9vw;
    left: 15%;
  }
  .restauration-main-content{
    display: flex;
    width: 100%;
    position: relative;
    margin: 5% 0 5% 0;
    .restauration-main-content-image2 {
      width: 10vw;
      height: auto;
      object-fit: scale-down;
      position: absolute;
      //top:12%;
      bottom: -5vw;
      left: -7%;
    }
      .restauration-main-content-corpus{
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: auto;
        .restauration-main-content-corpus-title{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto 60px auto;
          text-align: center;
          h3{
            font-family: $textFont;
          }
          h2{
            font-family: $titleFont;
            font-size: 2.5rem;
            font-weight: normal;
            margin:0;
            color: $red;
          }
        }
        .restauration-main-content-text{
          text-align: justify;
          font-size: 1.1rem;
          font-weight: 200;
          line-height: 1.5;
        }
      }
      .restauration-main-content-image3{
        width: 21vw;
        height: auto;
        object-fit: scale-down;
        position: absolute;
        bottom: -5vw;
        right: -15%;
      }
  }
}

@media (max-width: 768px) {
  .restauration{
    padding-top: 20vw;
    padding-bottom: 20vw;
    .restauration-image1{
      left: 5%;
      width: 55vw;
    }
    .restauration-main-content{
      .restauration-main-content-image2{
        left: 5%;
        width: 15vw;
        bottom: -30vw;
      }
      .restauration-main-content-image3{
        right: -2%;
        width: 30vw;
        bottom: -30vw;
      }
      .restauration-main-content-corpus{
        width: 80%;
      }
    }
  }
}