@import './variables.scss';

.thumbnailsContainer{
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .thumbnailsContainer-title{
    font-family:$titleFont;
    font-size: 2.1rem;
    text-align: center;
    margin: 10% auto;
  }
  .thumbnailsContainer-links{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 50px;
    .thumbnailsContainer-links-thumbnails{
      width: 20%;
      img{
        width: 100%;
        height: auto;
        object-fit: cover;
        &:hover{
          filter: brightness(85%);
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1440px){
  .thumbnailsContainer{
    width: 80%;
    .thumbnailsContainer-links{
      .thumbnailsContainer-links-thumbnails{
        width: 30%;
      }
    }
  }
}
@media (max-width: 768px){
  .thumbnailsContainer{
    width: 80%;
    .thumbnailsContainer-title{
      font-size: 2rem;
    }
    .thumbnailsContainer-links{
      .thumbnailsContainer-links-thumbnails{
        width: 30%;
      }
    }
  }
}