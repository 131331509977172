@import './variables.scss';

.footer{
  background-color: $footer-bg-color;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  .footer-title{
    font-family: $titleFont;
    font-weight: normal;
    font-size: 2rem;
  }
  .footer-links{
    display: flex;
    justify-content: space-evenly;
    width: 60%;
    .footer-links-link{
      text-decoration: none;
      display: flex;
      color: white;
      border: 1px solid white;
      padding: 1%;
      border-radius:10px;
      font-size: 1.2rem;
      width: 40%;
      height: 50px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  .footer-link-istorio{
    text-decoration: none;
    color: white;
    margin-top: 2%;
  }
}

@media (min-width: 768px) and (max-width: 1440px){
  .footer{
    .footer-links{
      width: 100%;
      .footer-links-link{
        width:45%;
      }
    }
  }
}

@media (max-width: 768px){
  .footer{
    .footer-links{
      width: 100%;
      .footer-links-link{
        width:45%;
        font-size: 0.9rem;
        align-self: center;
      }
    }
  }
}