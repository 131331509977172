@import './variables.scss';

.lieu {
  padding-top: 70px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  font-family: $textFont;
  .lieu-header {
    width: 80vw;
    height: 460px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    background-color: black;
    margin-bottom: 60px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    .lieu-header-left {
      width: 75%;
      height: 460px;
      border-radius: 20px 0 0 20px;
      position: relative;
      box-sizing: border-box;
    }
    .lieu-header-right {
      width: 25%;
      height: 460px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 0 20px 20px 0;
      font-size: 17px;
      box-sizing: border-box;
    }
  }
  .lieu-contenu-row {
    display: flex;
    flex-direction: row;
    width: 80vw;
    box-sizing: border-box;
    flex-wrap: wrap;
    .lieu-contenu-left {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      box-sizing: border-box;
      width: calc(75% - 30px);
    }
    .lieu-contenu-right {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 25%;
    }
  }
  .lieu-proximite {
    width: 80vw;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
  }
}

.lieu-modal {
  width: 640px;
  padding: 45px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

@media (max-width: 768px){
    .lieu {
      .lieu-header {
        width: 90vw;
        height: 300px;
        flex-direction: column;
        .lieu-header-left {
          width: 100%;
          height: 300px;
          border-radius: 20px 20px 0 0;
        }
        .lieu-header-right {
          width: 100%;
          height: 100px;
          border-radius: 0 0 20px 20px;
          font-size: 14px;
        }
      }
      .lieu-contenu-row {
        flex-direction: column;
        width: 90vw;
        .lieu-contenu-left {
          width: 100%;
          margin-right: 0;
        }
        .lieu-contenu-right {
          width: 100%;
        }
      }
      .lieu-proximite {
        width: 90vw;
      }
    }
  .lieu-modal {
    width: 90vw;
    padding: 30px;
  }
}