@import './variables.scss';
.village {
  .village-carte {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    background: #FFFFFF;
    .village-carte-titre {
      font-family: $textFont;
      font-size: 1.25rem;
      color: $darkTextColor;
      font-weight: 600;
      margin: 0;
      margin-bottom: 12px;
      margin-top: 70px;
    }
    .village-carte-soustitre {
      font-family: $titleFont;
      font-size: 2.5rem;
      color: $background;
      margin: 0;
      margin-bottom: 60px;
      text-align: center;
    }
    .village-carte-carte {
      width: 70%;
      height: 70vh;
      background-color:#D9D9D9;
      border-radius:10px;
      margin:1% auto 5%;
      overflow: hidden;
      z-index: 1;
    }
  }
}