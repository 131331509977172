@import './variables.scss';

.pageDescription{
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: 50%;
  margin: auto;
  padding: 5% 0 5% 0;
  .pageDescription-title{
    color: $lightTextColor;
    font-family: $titleFont;
    font-size: 2.1rem;
    text-align: center;
  }
  .pageDescription-description{
    font-family: $textFont;
    color: $lightTextColor;
    text-align: center;
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) and (max-width: 1440px){
  .pageDescription{
    padding: 5% 1% 5% 1%;
    .pageDescription-title{
      font-size: 1.5rem;
    }
    .pageDescription-description{
      font-size: 1rem;
      line-height: 150%;
    }
  }
}
@media (max-width: 768px){
  .pageDescription{
    padding: 5% 1% 5% 1%;
    .pageDescription-title{
      font-size: 1.1rem;
    }
    .pageDescription-description{
      font-size: 0.8rem;
    }
  }
}