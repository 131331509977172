@import './variables.scss';

.parcours-description-component{
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: relative;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%);
  .parcours-description-component-image1{
    position: absolute;
    top: -5%;
    left: -8%;
    width: 12%;
  }
  .parcours-description-text{
    font-family: $textFont;
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 1.5;
  }
  .parcours-description-link{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    .parcours-description-link-button{
      text-decoration: none;
      color: $lightTextColor;
      font-family: $titleFont;
      width: 30%;
      background-color:#8A7446;
      height: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
  }
  .parcours-description-component-image2{
    position: absolute;
    top: 15%;
    right: -9%;
    width: 12%;
  }
}


@media (max-width: 768px){
  .parcours-description-component {
    .parcours-description-component-image1{
      display: none;
    }
    .parcours-description-component-image2{
        display: none;
    }
  }
}