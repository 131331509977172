@import './variables.scss';

.infomodal{
  display: flex;
  //display: none;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  h2{
    font-family: $titleFont;
    color: $red;
    margin-bottom: 5%;
    text-align: center;
  }
  .infomodal-rubrique{
    display:flex;
    margin-bottom: 1%;
    .infomodal-rubrique-icone{
      width: 8%;
      height: auto;
      object-fit: scale-down;
      align-self: flex-start;
    }
    .infomodal-rubrique-infos{
      display: flex;
      flex-direction: column;
      margin:1% 0;
      width: 100%;
      font-family: $textFont;
      color:$darkTextColor;
      .infomodal-rubriques-infos-dates {
        display: flex;
        width: 25%;
        justify-content: space-between;
        p{
          margin: 0;
        }
        img{
          width: 20px;
          padding: 0 2% 1%;
          object-fit: scale-down;
        }
      }
      li{
        list-style-type: none;
        margin: 1% 0;
      }
    }
    .infomodal-rubrique-transports{
      display: flex;
      flex-direction: column;
      margin-top: 1%;
      font-family: $textFont;

    }
    .infomodal-rubrique-lignes{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      img{
        width: 7%;
        margin: 1%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1440px){

}
@media (max-width: 768px){
  .infomodal{
    .infomodal-rubrique{
      .infomodal-rubrique-infos{
        font-size: 0.8rem;
      }
    }
}


}