@import './variables.scss';

.header-description {
    width: 100%;
    height: 150vh;
    //max-width: 1725px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.8) 100%), url('../Assets/stNicolas.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

.hommePage-description {
    //height: 42vh;
    //max-width: 1725px;
    background-color: $background;
    margin: 0 auto;
  }

.homePage-partenaires {
    //height: 50vh;
    //max-width: 1725px;
    margin: 0 auto;
    background-color:$bg-color-main;
    padding-bottom: 5%;
  }

.homePage-aventures{
    //height: 60vh;
    //max-width: 1725px;
    margin: 0 auto;
}

@media (min-width: 768px) and (max-width: 1440px){
    .header-description{
        height: 100vh;
    }
}
@media (max-width: 768px){
    .header-description{
        height: 90vh;
    }
}